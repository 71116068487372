<template>
  <section id="film-distributor" v-loading="updateLoading">
    <b-card>
      <div class="title">
        <p>{{ infoPageTitle }}</p>
      </div>

      <div>
        <b-button variant="outline-secondary" @click="goBackInvestInfo()">
          回目錄
        </b-button>
      </div>
      <div>
        <b-form>
          <div v-for="item in items" :id="item.id" :key="item.id">
            <b-row :id="item.id" :key="item.id" ref="row">
              <b-col cols="12" class="mt-2">
                <p class="sub-title">
                  Production company ID :
                  {{ pageIdVal == "edit" ? item.id : "Create New ID" }}
                </p>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Production company Code (10位)"
                  label-for="invest-name-code"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-code"
                    label="Production company Code (10位)"
                    v-model="item.production_code"
                    name="production_code"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Production company Name"
                  label-for="invest-name-en"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-en"
                    label="導演名稱"
                    v-model="item.production_name_en"
                    name="production_name_en"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="出品公司名稱"
                  label-for="invest-name-ch"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-ch"
                    label="出品公司名稱"
                    v-model="item.production_name_zh_hk"
                    name="production_name_zh_hk"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="地區" label-for="area" label-cols-md="3">
                  <b-form-select
                    v-model="item.area"
                    :options="selectOptions"
                    :disabled="disableChange"
                    :clearable="false"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="合拍片"
                  label-for="co_production"
                  label-cols-md="3"
                >
                  <b-form-select
                    @change="selectCoProd"
                    v-model="item.co_production"
                    :options="selectCoOptions"
                    :disabled="disableChange"
                    :clearable="false"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Remark"
                  label-for="remark"
                  label-cols-md="3"
                >
                  <b-form-textarea
                    id="prod_remark"
                    v-model="item.remark"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                    :disabled="disableChange"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <hr />
              </b-col>
              <!-- submit and reset -->
              <b-col offset-md="5" v-if="pageType === 'create'">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submitForm('create', 0)"
                  :disabled="disableChange"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </div>
    </b-card>
  </section>
</template>
<script>
import {
  createProduction,
  editProduction
} from "@/api/film-material-management/productionCompany";
import { getSelfProfile } from "@/api/login";
import {
  changeOldRecord,
  deleteOldRecord
} from "@/api/film-material-management/oldRecord";
import datePickerCom from "@/views/components/datePicker.vue";

export default {
  name: "ProductionCompanyForm",
  components: {
    datePickerCom
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => [
        {
          id: "",
          production_name_en: "",
          production_name_zh_hk: "",
          production_code: "",
          area: "",
          old_record: [],
          remark: ""
        }
      ]
    }
  },
  data() {
    return {
      dateFormat: "d-m-Y",
      selectCoOptions: [
        { text: "yes", value: 1 },
        { text: "no", value: 0 }
      ],
      userInfo: "",
      disableChange: true,
      borderClass: "",
      pageId: "",
      pagePath: "",
      pageType: "",
      select: "",
      selectOptions: [
        { text: "香港", value: "Hong Kong" },
        { text: "非香港", value: "Non Hong Kong" }
      ],
      // dateDefault: 'today',
      formdata: {
        id: "",
        production_name_en: "",
        production_name_zh_hk: "",
        production_code: "",
        area: "",
        co_production: "",
        old_record: []
      }
    };
  },
  created() {
    this.pageType = this.$route.query.type;
    this.pageId = this.$route.query.id;
    this.pagePath = this.$route.path;
    console.log(this.pageType);
    if (this.pageType === "review") {
      // this.disableChange = false
    } else if (this.pageType === "edit") {
      // this.dateDefault = this.items[0].old_record[0].change_date
      this.borderClass = "redborder";
      this.getSelfProfile();
    } else {
      this.disableChange = false;
    }
  },
  computed: {
    infoPageTitle() {
      if (this.pageType === "review") {
        return `${this.title}記錄`;
      } else if (this.pageType === "edit") {
        return `修訂${this.title}記錄`;
      } else {
        return `開立新${this.title}記錄`;
      }
    },
    pageIdVal() {
      if (this.pageType === "review") {
        return "edit";
      } else if (this.pageType === "edit") {
        return "edit";
      } else {
        return "Create New ID";
      }
    },
    updateLoading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      }
    }
  },
  methods: {
    selectCoProd(val) {
      console.log(val);
      if (val === 1) {
        if (!this.items[0].production_code.includes("*")) {
          this.items[0].production_code = `${this.items[0].production_code}*`;
        }
        if (!this.items[0].production_name_en.includes("*")) {
          this.items[0].production_name_en = `${this.items[0].production_name_en}*`;
        }
        if (!this.items[0].production_name_zh_hk.includes("*")) {
          this.items[0].production_name_zh_hk = `${this.items[0].production_name_zh_hk}*`;
        }
      } else {
        this.items[0].production_code = this.items[0].production_code.replace(
          "*",
          ""
        );
        this.items[0].production_name_en =
          this.items[0].production_name_en.replace("*", "");
        this.items[0].production_name_zh_hk =
          this.items[0].production_name_zh_hk.replace("*", "");
      }
    },
    deleteHistory(id) {
      deleteOldRecord("production", id)
        .then((res) => {
          console.log(res);
          this.$emit("getProductionDetail");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    historySave(id, index) {
      this.updateLoading = true;
      console.log(this.items[0].old_record[index]);
      const data = {
        name_en: this.items[0].old_record[index].name_en,
        name_zh_hk: this.items[0].old_record[index].name_zh_hk,
        change_date: this.items[0].old_record[index].change_date
      };
      changeOldRecord("production", id, data)
        .then((res) => {
          console.log(res);
          this.updateLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.updateLoading = false;
        });
    },
    editChange(index, type) {
      if (type === "edit") {
        this.items[0].old_record[index].disabled = false;
      } else if (type === "cancel") {
        this.items[0].old_record[index].disabled = true;
      } else if (type === "all-cancel") {
        this.disableChange = true;
        this.items[0].old_record.map((el) => {
          el.disabled = true;
        });
      }
    },
    getSelfProfile() {
      getSelfProfile()
        .then((res) => {
          this.userInfo = res.data;
          console.log(this.userInfo);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBackInvestInfo() {
      this.$router.push({ name: "production-company" });
    },
    toEdit() {
      this.$router.push({
        path: this.pagePath,
        query: { type: "edit", id: this.pageId }
      });
      this.disableChange = false;
      this.borderClass = "redborder";
    },
    submitForm(type, revise) {
      const data = {
        production_name_en: this.items[0].production_name_en,
        production_name_zh_hk: this.items[0].production_name_zh_hk,
        production_code: this.items[0].production_code,
        area: this.items[0].area,
        is_jv: this.items[0].co_production,
        revise,
        remark: this.items[0].remark
      };
      console.log(type, data);
      if (type === "create") {
        createProduction(data)
          .then((res) => {
            this.formdata.production_name_en = res.data.production_name_en;
            this.formdata.production_name_zh_hk =
              res.data.production_name_zh_hk;
            this.formdata.production_code = res.data.production_code;
            this.formdata.area = res.data.area;
            this.$router.push({
              name: "edit-production-company",
              query: {
                type: "review",
                id: res.data.id
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        editProduction(this.items[0].id, data)
          .then((res) => {
            console.log(res);
            this.$emit("getProductionDetail");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.line {
  height: 3px;
  color: #0e72bc;
}
.redborder {
  border-color: red;
}
</style>
